<template>
  <div class="app">
    <div class="common-content">
      <el-row>
        <el-col class="test-img">
          <img src="../assets/images/test-img.jpg" alt />
        </el-col>
        <el-col>
          <el-row class="test-title">
            <el-col>
              <h4>测试试卷</h4>
              <p>
                <span>时间：60分钟</span>
                <span>标签：人事</span>
              </p>
              <p>(测试试卷 不可补考)</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form>
                <el-row>
                  <el-col>
                    <el-form-item>
                  <h4>一、选择题 (共50分)</h4>
                </el-form-item>
                <el-form-item >
                  <p><i class="item-order">1</i><span>我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？</span></p>
                  <el-radio-group v-model="form.radio1">
                    <el-radio :label="1">A.雨果</el-radio>
                    <el-radio :label="2">B.百伦</el-radio>
                    <el-radio :label="3">C.雪莱</el-radio>
                    <el-radio :label="4">D.普斯金</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item >
                  <p><i class="item-order">2</i><span>我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？</span></p>
                  <el-radio-group v-model="form.radio2">
                    <el-radio :label="1">A.雨果</el-radio>
                    <el-radio :label="2">B.百伦</el-radio>
                    <el-radio :label="3">C.雪莱</el-radio>
                    <el-radio :label="4">D.普斯金</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item >
                  <p><i class="item-order">3</i><span>我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？</span></p>
                  <el-radio-group v-model="form.radio3">
                    <el-radio :label="1">A.雨果</el-radio>
                    <el-radio :label="2">B.百伦</el-radio>
                    <el-radio :label="3">C.雪莱</el-radio>
                    <el-radio :label="4">D.普斯金</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item >
                  <p><i class="item-order">4</i><span>我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？我们平时说‘如果冬天来了，春天还会远吗，请问这句名言出自英国的哪位作家？</span></p>
                  <el-radio-group v-model="form.radio4">
                    <el-radio :label="1">A.雨果</el-radio>
                    <el-radio :label="2">B.百伦</el-radio>
                    <el-radio :label="3">C.雪莱</el-radio>
                    <el-radio :label="4">D.普斯金</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-submit">
                   <el-button type="warning" plain @click="submit()">提交试卷</el-button>
                </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        radio1: 1,
        radio2: 2,
        radio3: 3,
        radio4: 4
      }
    }
  },
  created: function () {},
  methods: {
    submit () {
      console.log(this.form)
    }
  }
}
</script>

<style lang="less">
.app {
  margin: 40px 0;
  .common-content {
    box-shadow: 0 0 30px @global-shadow-color;
    .test-img {
      img {
        width: 100%;
      }
    }
    .test-title {
      h4 {
        margin-bottom: 0;
      }
      p {
        span {
          margin: 0 10px;
        }
      }
    }
    .el-form {
      padding: 0 50px 20px;
      .el-form-item {
        text-align: left;
        .el-form-item__content{
          line-height: 20px
        }
        h4 {
          text-align: left;
          margin: 0;
        }
        .item-order {
          font-style:normal;
          padding: 0 10px;
          font-size: 12px;
          border-radius: 10px;
          margin-right: 5px;
          background: @global-title-p-color;
          color: @global-white;
        }
        p {
          display: inline-block;
          letter-spacing: 2px;
          margin: 0;
          margin-bottom:20px
        }
        .el-radio-group{
          display: block;
          /deep/.el-radio__input.is-checked .el-radio__inner{
            border-color:@global-submit-color;
            background: @global-submit-color;
          }
          /deep/.el-radio__input.is-checked+.el-radio__label{
            color:@global-text-color;
          }
        }
      }
      .form-submit{
        text-align: center;
        .el-button{
          background: @global-submit-color;
          color:@global-text-color;
          border-radius: 20px;
          padding:12px 50px
        }
      }
    }
  }
}
</style>
